@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #aaa;
}

body {
  background: #202228;
}

.body {
  min-height: 100vh;
  background: #202228 url("img/background.png");
  color: #989eae;
  font-family: "Rubik", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  white-space: pre-line;
  vertical-align: bottom;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
header .hero {
  width: 95%;
  margin-top: 5px;
  max-width: 1200px;
  background-image: url("img//banner.png");
  background-size: cover;
  background-position: center;
  padding: 25px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
  border-radius: 5px;
}
@media (min-width: 786px) {
  header .hero {
    padding: 50px;
    margin-top: 10px;
  }
}
header .hero h1 {
  font-size: 2rem;
  color: white;
  text-shadow: 0 0 6px black;
}
header .hero h2 {
  font-size: 1.3rem;
  font-weight: 300;
  text-shadow: 0 0 6px black;
}
header .menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
header .menu .menu-button {
  display: block;
  background: #aaa;
  border: 0;
  padding: 15px;
  transition: width 0.2s;
  text-decoration: none;
  color: black;
  font-weight: bold;
  border-radius: 5px;
}
@media (min-width: 786px) {
  header .menu .menu-button {
    display: none;
  }
}
header .menu nav {
  transition: height 0.5s ease;
  overflow: hidden;
  background: black;
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
}
@media (min-width: 786px) {
  header .menu nav {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}
header .menu nav a {
  display: block;
  font-size: 0.9rem;
  padding: 10px;
  margin: 0 5px;
  text-decoration: none;
  color: #ccc !important;
  font-weight: bold;
  transition: background-color 0.1s;
  border-radius: 5px;
}
header .menu nav a.active {
  background: #333;
  border-radius: 5px;
  color: #fff;
}
header .menu nav a:hover {
  background-color: #555;
  color: #fff;
  border-radius: 5px;
}
header .menu nav:not(.active) {
  display: none;
}
@media (min-width: 786px) {
  header .menu nav:not(.active) {
    display: flex;
  }
}

main {
  width: 95%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
@media (min-width: 786px) {
  main {
    grid-template-columns: 4fr 1fr;
  }
}
main .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: black;
  padding: 20px 10px;
  border-radius: 5px;
}
main .content h3 {
  margin-bottom: 15px;
  font-size: 2rem;
}
@media (min-width: 786px) {
  main .content {
    align-items: start;
    padding: 25px;
  }
}
main .content p {
  margin: 10px 0;
}
main .callout {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
main .callout div {
  background: black;
  padding: 25px;
  border-radius: 5px;
}
main .callout h2 {
  margin: 10px 0;
  font-size: 1.6rem;
}
main .callout h4 {
  margin-top: 15px;
}
main .callout img {
  border-radius: 5px;
}
main ul {
  padding-left: 25px;
}
main ul li {
  margin: 5px 0;
}
main .cards {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
}
@media (min-width: 786px) {
  main .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
main .cards .card {
  background: #333;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}
@media (min-width: 786px) {
  main .cards .card {
    padding: 15px;
    line-height: 1.5;
  }
}

.compositions {
  width: 100%;
  display: grid;
  grid-gap: 25px;
}
@media (min-width: 786px) {
  .compositions {
    grid-template-columns: 4fr 3fr;
  }
}
.compositions .player {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
.compositions .player audio {
  width: 100%;
  margin-bottom: 20px;
}
.compositions .player h4 {
  font-size: 1.2rem;
  display: inline;
}
.compositions .playlist {
  padding: 10px 10px 10px 40px;
}
@media (min-width: 786px) {
  .compositions .playlist {
    padding-left: 20px;
  }
}
.compositions .playlist li {
  margin-bottom: 10px;
  cursor: pointer;
}

footer {
  font-size: 0.7rem;
  margin: 10px 0;
}

