@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

$border-radius:5px;

@mixin tablet{
  @media (min-width:786px){
    @content;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a{
  text-decoration: none;
  color:#aaa;
}

body{
  background: #202228;
}

.body{
  //background: #202228;
  min-height:100vh;
  background: #202228 url('img/background.png');
  color: #989eae;
  font-family: 'Rubik', sans-serif;
  display:flex;
  flex-direction:column;
  align-items:center;
}

p{
  white-space: pre-line;
  vertical-align: bottom;
}

header{
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
  .hero{
    width:95%;
    margin-top:5px;
    max-width:1200px;
    background-image: url("img//banner.png");
    background-size: cover;
    background-position:center;
    padding:25px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
    border-radius: $border-radius;
    @include tablet{
      padding:50px;
      margin-top:10px;
    }
    h1{
      font-size:2rem;
      color:white;
      text-shadow: 0 0 6px black;
    }
    h2{
      font-size:1.3rem;
      font-weight:300;
      text-shadow: 0 0 6px black;
    }
  }


  .menu{
    display:flex;
    flex-direction:column;
    align-items:center;
    margin:10px;
    .menu-button{
      display:block;
      background:#aaa;
      border:0;
      padding:15px;
      transition:width 0.2s;
      text-decoration: none;
      color:black;
      font-weight:bold;
      border-radius: $border-radius;
      @include tablet{
        display:none;
      }
    }
    nav{
      transition: height .5s ease;
      overflow: hidden;
      background:black;
      display:flex;
      flex-direction:column;
      margin:10px;
      border-radius:$border-radius;
      padding:5px;
      width:100%;
      @include tablet{
        flex-direction:row;
        align-items:center;
        flex-wrap: wrap;
        justify-content: center;
      }
      a{
        display:block;
        font-size:0.9rem;
        padding:10px;
        margin:0 5px;
        text-decoration: none;
        color:#ccc !important;
        font-weight:bold;
        transition: background-color 0.1s;
        border-radius:5px;
        &.active{
          background:#333;
          border-radius:5px;
          color:#fff;
        }
        &:hover{
          background-color:#555;
          color:#fff;
          border-radius:5px;
        }
      }
    }
    nav:not(.active) {
      display:none;
      @include tablet{
        display:flex;
      }
    }
  }
  }



main{
  width:95%;
  max-width:1200px;
  display:grid;
  grid-template-columns: 1fr;
  grid-gap:15px;
  @include tablet{
    grid-template-columns: 4fr 1fr;
  }
  .content{
    h3{
      margin-bottom:15px;
      font-size:2rem;
    }
    display:flex;
    flex-direction: column;
    align-items:center;
    background:black;
    padding:20px 10px;
    border-radius: $border-radius;
    @include tablet{
      align-items:start;
      padding:25px;
    }
    p{
      margin:10px 0;
    }
  }
  .callout{
    display:flex;
    flex-direction:column;
    align-items:center;
    text-align:center;
    div {
      background:black;
      padding:25px;
      border-radius: $border-radius;
    }
    h2{
      margin:10px 0;
      font-size:1.6rem;
    }
    h4{
      margin-top:15px;
    }
    img{
      border-radius:$border-radius;
    }
    @include tablet{
    }
  }
  ul{
    padding-left:25px;
    li{
      margin:5px 0;
    }
  }
  .cards{
    display:grid;
    grid-gap:10px;
    grid-template-columns: 1fr;
    @include tablet{
      grid-template-columns: repeat(3, 1fr);
    }
    .card{
      background:#333;
      border-radius: $border-radius;
      padding:5px;
      text-align:center;
      @include tablet{
        padding:15px;
        line-height:1.5;
      }
    }
  }
}

.compositions{
  width:100%;
  display:grid;

  @include tablet{
    grid-template-columns: 4fr 3fr;
  }
  grid-gap:25px;
  .player{
    display:flex;
    flex-direction:column;
    align-items:start;
    width:100%;
    audio{
      width:100%;
      margin-bottom:20px;
    }
    h4{
      font-size:1.2rem;
      display:inline;
    }
  }
  .playlist{
    padding: 10px 10px 10px 40px;
    @include tablet{
      padding-left:20px;
    }
    li{
      margin-bottom:10px;
      cursor:pointer;
    }
  }
}



footer{
  font-size: 0.7rem;
  margin:10px 0;
}
